export enum ConstantEnum {
  alternativeSolution = 'alternativeSolution',
  applicationEquipment = 'applicationEquipment',
  area = 'area',
  areaUnit = 'areaUnit',
  associatedCrop = 'associatedCrop',
  campaign = 'campaign',
  contactType = 'contactType',
  country = 'country',
  cropType = 'cropType',
  cropVigor = 'cropVigor',
  drainage = 'drainage',
  drainageClassification = 'drainageClassification',
  drainageType = 'drainageType',
  drainageWidth = 'drainageWidth',
  environment = 'environment',
  fieldBuffer = 'fieldBuffer',
  fieldType = 'fieldType',
  foodSafety = 'foodSafety',
  growingSystem = 'growingSystem',
  growthStage = 'growthStage',
  hypothesisType = 'hypothesisType',
  interventionSubtype = 'interventionSubtype',
  interventionType = 'interventionType',
  invoiceInformation = 'invoiceInformation',
  irrigationEquipement = 'irrigationEquipement',
  irrigationUnit = 'irrigationUnit',
  laboratoryType = 'laboratoryType',
  lengthUnit = 'lengthUnit',
  macroNutrient = 'macroNutrient',
  microNutrient = 'microNutrient',
  nutrientsQuantityUnit = 'nutrientsQuantityUnit',
  obstruction = 'obstruction',
  operationType = 'operationType',
  pesticideType = 'pesticideType',
  preharvestControlType = 'preharvestControlType',
  pressure = 'pressure',
  previousCropType = 'previousCropType',
  productionArea = 'productionArea',
  qualityDefect = 'qualityDefect',
  rawMaterialProcessed = 'rawMaterialProcessed',
  recommendationSubtype = 'recommendationSubtype',
  recommendationType = 'recommendationType',
  sampleTest = 'sampleTest',
  sampleType = 'sampleType',
  samplePesticideType = 'samplePesticideType',
  scoutingItem = 'scoutingItem',
  scoutingSubitem = 'scoutingSubitem',
  scoutingSubtype = 'scoutingSubtype',
  scoutingType = 'scoutingType',
  seedingRateUnit = 'seedingRateUnit',
  soilConditions = 'soilConditions',
  soilTexture = 'soilTexture',
  stage = 'stage',
  stonePressure = 'stonePressure',
  supplierType = 'supplierType',
  topography = 'topography',
  targetPest = 'targetPest',
  temperatureUnit = 'temperatureUnit',
  waterSource = 'waterSource',
  weatherConditions = 'weatherConditions',
  weatherDamage = 'weatherDamage',
  weightUnit = 'weightUnit',
  windDirection = 'windDirection',
  windSpeedUnit = 'windSpeedUnit',
  worklistTaskList = 'worklistTaskList',
  csrRegenAgriAnswer = 'csrRegenAgriAnswer',
  harvestGroupementKey = 'harvestGroupementKey',
  tillageCondition = 'tillageCondition',
}
