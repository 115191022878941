import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { environment } from '@front-app-environments/environment'

@Component({
  selector: 'md-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() isExpanded: boolean = false
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>()

  public agrooneUrl!: string

  ngOnInit() {
    this.agrooneUrl = environment.agrooneUrl
  }

  expandSidebar() {
    this.isExpanded = !this.isExpanded
    this.toggleSidebar.emit()
  }
}
